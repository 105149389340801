var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"params-block__insurance"},[(_vm.isViewApproved)?_c('div',{staticClass:"row mb-3",staticStyle:{"margin-top":"-37px"}},[_c('div',{staticClass:"col-6"}),_vm._m(0),_vm._m(1)]):_vm._e(),_vm._l((_vm.titles),function(title){return _c('div',{key:title.code,staticClass:"row mb-2"},[_c('div',{staticClass:"col-6"},[_c('span',{staticClass:"color-label"},[_vm._v(_vm._s(title.name))])]),_c('div',{staticClass:"col text-sm-right"},[(title.type === 'rub')?_c('UnitRub',{attrs:{"value":_vm.dataRequested[title.code]}}):(title.type === 'percent')?_c('UnitPercent',{attrs:{"value":_vm.dataRequested[title.code]}}):_c('span')],1),(_vm.isViewApproved)?_c('div',{staticClass:"col-3 text-sm-right"},[(!_vm.isEditApproved)?[(title.type === 'rub')?_c('UnitRub',{attrs:{"value":_vm.dataApproved[title.code]}}):(title.type === 'percent')?_c('UnitPercent',{attrs:{"value":_vm.dataApproved[title.code]}}):_c('span')]:_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"row input-wrapper"},[_c('div',{staticClass:"col pr-0 text-xs-right"},[_c('unicus-autonumeric-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|min_value:0'),expression:"'required|min_value:0'"}],class:{ 'has-error': _vm.errors.has(`form.${title.code}`) },attrs:{"hide-details":"","data-vv-scope":"form","data-vv-delay":"300","name":title.code,"max-value":title.type === 'percent' ? 99.99 : undefined},model:{value:(_vm.dataApproved[title.code]),callback:function ($$v) {_vm.$set(_vm.dataApproved, title.code, $$v)},expression:"dataApproved[title.code]"}})],1),_c('div',{staticClass:"col-auto align-self-center pl-0"},[_c('i',{staticClass:"fal pl-1",class:{
                'error--text': _vm.errors.has(`form.${title.code}`),
                'fa-ruble-sign': title.type === 'rub',
                'fa-percent': title.type === 'percent'
              }})])])])],2):_vm._e()])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col text-sm-right"},[_c('span',{staticClass:"color-label"},[_vm._v("Заявлено")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3 text-sm-right"},[_c('span',{staticClass:"color-label"},[_vm._v("Одобрено")])])
}]

export { render, staticRenderFns }